import React from "react";
import { useLoaderData, Link } from "react-router-dom";
import { db } from "../data/db1";

export default function News() {
  const news = useLoaderData();

  return (
    <div className="resources-layout news">
      {news.map((post) => (
        <div className="new" key={post.id}>
          <div className="news_title">
            <p>
              <strong>{post.title}</strong>
            </p>
            <p>{post.endTime}</p>
          </div>
          <p>{post.description}</p>
          <p className="readmore">
            <Link to={post.id.toString()}>Read More...</Link>
          </p>
        </div>
      ))}
    </div>
  );
}

// export const newsLoader = async () => {
//   const res = await fetch("http://localhost:4000/news");
//   console.log(res);
//   return res.json();
// };

export const newsLoader = () => {
  const res = db.news;
  if (!res) {
    throw Error("No news at all");
  }
  return res;
};
