import React from "react";
import { Outlet, NavLink } from "react-router-dom";

export default function ResourcesLayout() {
  return (
    <div className="resources-layout">
      <h2>Subscibe to wonderful learning resources</h2>
      <p>
        Student learning resources are an essential tool for students to acquire
        knowledge, improve their skills and prepare for their academic and
        professional careers. These resources can range from textbooks, online
        courses, and educational videos to study groups, tutors, and mentors.
        The accessibility and quality of these resources have a significant
        impact on the learning outcomes of students. With the advances in
        technology, students now have access to a vast array of online resources
        and digital tools, which have made learning more engaging, interactive,
        and personalized. With the right learning resources, students can
        develop their critical thinking skills, boost their creativity, and
        become more confident and successful learners.
      </p>
      <p>KoalaLooka provides below subscription yearly: </p>
      <nav>
        <NavLink to="Raz">Raz Leveled Reading</NavLink>
        <NavLink to="CricketMagzine">Cricket Magzine</NavLink>
      </nav>

      <Outlet />
    </div>
  );
}
