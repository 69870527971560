import { useParams, useLoaderData } from "react-router-dom";
import { db } from "../data/db1";

export default function NewDetail() {
  const { id } = useParams();
  const post = useLoaderData()[0];
  if (!post) return <div>No News</div>;
  return (
    <div className="new">
      <div className="news_title">
        <p>
          <strong>{post.title}</strong>
        </p>
        <p>{post.endTime}</p>
      </div>
      <p>{post.description}</p>
    </div>
  );
}

export const newDetailsLoader = ({ params }) => {
  const { id } = params;
  const post1 = db.news.filter((p) => p.id === id);

  if (!post1) {
    throw Error("Could not find that news");
  }

  return post1;
};
