import React from "react";

export default function Home() {
  return (
    <div className="home">
      <h2>Welcom</h2>
      <p>
        KoalaLooka Learning Club is an online learning community with selective
        courses and resources for creative and curious students and parents on
        Programming, Reading, Writing, Chinese, Math and more. We dedicate to
        providing valuable education applications/platforms! On KoalaLooka,
        members come together to find and share inspiration and take the next
        step in their creative learning journey. We empower members to Learn new
        knowledge and skills. Make discoveries. Challenge their potential. Get
        inspired.
      </p>
      <p>
        We empower members to:
        <ul>
          <li>Learn new knowledge and skills</li>
          <li>Make discoveries</li>
          <li>Challenge their potential</li>
          <li>Get inspired</li>
        </ul>
      </p>
      <h2>Contact Us</h2>
      <form method="post" action="/help/contact">
        <label>
          <span>Your name:</span>
          <input type="name" name="name" required />
        </label>
        <label>
          <span>Your email:</span>
          <input type="email" name="email" required />
        </label>
        <label>
          <span>Your message:</span>
          <textarea name="message" required></textarea>
        </label>
        <button>Submit</button>
      </form>
    </div>
  );
}
